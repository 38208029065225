import React from 'react'
import styled, { css, withTheme } from 'styled-components'
import { marginBreakpoints, hideBlockAt } from '../../../utils/commonAttrs'
import { normalizeHexColourCode } from '../../../libs/colours'
import { graphql } from 'gatsby'
import { Text } from 'mf-ui-components'
import AnimateOnScroll from '../../common/AnimateOnScroll'

const ParagraphTag = styled(Text)`
  width: 100%;
  ${props => marginBreakpoints(props)}
  ${props => hideBlockAt(props)}

  ${({ variant }) =>
    variant === 'inverted' &&
    css`
      > a {
        color: ${props => props.theme.tokens.text.label.reversed} !important;
      }
    `}
`

const getTextPropsFromParagraphSize = size => {
  switch (size) {
    case 'textLarge':
      return { styleName: 'bodyLarge', variant: 'secondary' }

    case null:
    case undefined:
    case 'normal':
    case 'textRegular':
      return { styleName: 'bodyRegular', variant: 'secondary' }

    case 'textLabel1':
      return { styleName: 'bodyRegular' }

    case 'small':
    case 'textSmall':
      return { styleName: 'bodySmall', variant: 'secondary' }
  }
}

function ParagraphBlock({
  id,
  children,
  fontSize,
  customTextColor,
  marginBottom,
  style,
  hiddenAt,
  textAlign,
  animateOnScroll,
  animationType,
  animationDelay,
  animationEasing,
  theme,
}) {
  const inverted =
    customTextColor &&
    normalizeHexColourCode(theme.tokens.text.paragraph.reversed) ===
      normalizeHexColourCode(customTextColor)

  const { styleName, variant = undefined } = getTextPropsFromParagraphSize(
    fontSize
  )
  const ParagraphElement = (
    <ParagraphTag
      id={id}
      el="p"
      styleName={styleName}
      variant={inverted ? 'inverted' : variant}
      size={fontSize}
      marginBottom={marginBottom}
      hiddenAt={hiddenAt}
      style={{ ...style, textAlign }}
    >
      {children}
    </ParagraphTag>
  )

  return animateOnScroll ? (
    <AnimateOnScroll
      animationType={animationType}
      animationDelay={animationDelay}
      animationEasing={animationEasing}
    >
      {ParagraphElement}
    </AnimateOnScroll>
  ) : (
    ParagraphElement
  )
}

export default withTheme(ParagraphBlock)

export const query = graphql`
  fragment paragraphFields on WPGraphQL_MfblocksParagraphBlock {
    name
    attributes {
      animateOnScroll
      animationType
      animationDelay
      animationEasing
      id
      marginBottom
      fontSize
      content
      customTextColor
      textAlign
      hiddenAt
    }
  }
`

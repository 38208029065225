import { IntlShape } from 'react-intl'

export const getContentCompact = (intl: IntlShape) => ({
  title: intl.formatMessage({ id: 'cookie.consent.compact.title' }),
  description: intl.formatMessage({
    id: 'cookie.consent.compact.description',
  }),
  secondaryCta: intl.formatMessage({
    id: 'cookie.consent.compact.cta.secondary',
  }),
  primaryCta: intl.formatMessage({
    id: 'cookie.consent.compact.cta.primary',
  }),
})

export const getContentDetail = (intl: IntlShape) => ({
  backLink: intl.formatMessage({ id: 'cookie.consent.detail.link.back' }),
  title: intl.formatMessage({ id: 'cookie.consent.detail.title' }),
  description: intl.formatMessage({
    id: 'cookie.consent.detail.description',
  }),
  secondaryCta: intl.formatMessage({
    id: 'cookie.consent.detail.cta.secondary',
  }),
  primaryCta: intl.formatMessage({ id: 'cookie.consent.detail.cta.primary' }),
  preferences: {
    title: intl.formatMessage({ id: 'cookie.consent.preferences.title' }),
    option: {
      mandatory: {
        label: intl.formatMessage({
          id: 'cookie.consent.option.mandatory.label',
        }),
        note: intl.formatMessage({
          id: 'cookie.consent.option.mandatory.label.note',
        }),
        description: intl.formatMessage({
          id: 'cookie.consent.option.mandatory.description',
        }),
      },
      consent_ad_storage: {
        label: intl.formatMessage({
          id: 'cookie.consent.option.consent_ad_storage.label',
        }),
        description: intl.formatMessage({
          id: 'cookie.consent.option.consent_ad_storage.description',
        }),
      },
      consent_analytics_storage: {
        label: intl.formatMessage({
          id: 'cookie.consent.option.consent_analytics_storage.label',
        }),
        description: intl.formatMessage({
          id: 'cookie.consent.option.consent_analytics_storage.description',
        }),
      },
      consent_personalization_storage: {
        label: intl.formatMessage({
          id: 'cookie.consent.option.consent_personalization_storage.label',
        }),
        description: intl.formatMessage({
          id:
            'cookie.consent.option.consent_personalization_storage.description',
        }),
      },
    },
  },
})

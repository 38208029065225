import React, { FC } from 'react'

import { Button } from './CloseButton.styles'

type Props = { handleClick: () => void; foregroundColour: string }

export const CloseButton: FC<Props> = ({ handleClick, foregroundColour }) => (
  <Button onClick={handleClick} foregroundColour={foregroundColour}>
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L9 9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 1L1 9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Button>
)

import { graphql, useStaticQuery } from 'gatsby'

import { Theme } from 'mf-ui-components'

export type StaticWebsiteData = {
  site: { siteMetadata: { title: string } }
  wpgraphql: {
    primary: { edges: { node: any }[] }
    footer1: { edges: { node: any }[] }
    footer2: { edges: { node: any }[] }
    footer3: { edges: { node: any }[] }
    footer4: { edges: { node: any }[] }
    legal: { edges: { node: any }[] }
    globalOptions: {
      generalOptions: { theme: Theme['id'] }
      footerContent: { content: any }
      topBanner: {
        htmlContent: any
        backgroundColour: string
        foregroundColour: string
        hideAfter?: string
        hideBefore?: string
        isDismissable: boolean
      }
    }
  }
}

export function useStaticData(): StaticWebsiteData {
  return useStaticQuery(graphql`
    query GetDataQuery {
      site {
        siteMetadata {
          title
        }
      }
      wpgraphql {
        globalOptions {
          topBanner {
            htmlContent
            backgroundColour
            foregroundColour
            hideAfter
            hideBefore
            isDismissable
          }
          footerContent {
            content {
              legal {
                section {
                  text
                  image {
                    mediaItemUrl
                  }
                }
              }
              business {
                registered
                copyright
              }
              contactBar {
                cta
                emailAddress
                tel
              }
              disclaimer {
                text
              }
              socialApp {
                social {
                  facebook
                  twitter
                  rss
                  linkedin
                  instagram
                }
                app {
                  apple {
                    link
                  }
                  android {
                    link
                  }
                }
              }
            }
          }
        }
        primary: menuItems(where: { location: PRIMARY }) {
          edges {
            node {
              title
              description
              label
              url
              target
              cssClasses
              menuItemId
              childItems {
                nodes {
                  url
                  menuItemId
                  label
                  cssClasses
                  target
                  childItems {
                    nodes {
                      url
                      menuItemId
                      label
                      cssClasses
                      target
                    }
                  }
                }
              }
            }
          }
        }
        footer1: menus(where: { location: FOOTER1 }) {
          edges {
            node {
              name
              slug
              menuItems {
                nodes {
                  label
                  url
                  target
                  cssClasses
                  menuItemId
                }
              }
            }
          }
        }
        footer2: menus(where: { location: FOOTER2 }) {
          edges {
            node {
              name
              slug
              menuItems {
                nodes {
                  label
                  url
                  target
                  cssClasses
                  menuItemId
                }
              }
            }
          }
        }
        footer3: menus(where: { location: FOOTER3 }) {
          edges {
            node {
              name
              slug
              menuItems {
                nodes {
                  label
                  url
                  target
                  cssClasses
                  menuItemId
                }
              }
            }
          }
        }
        footer4: menus(where: { location: FOOTER4 }) {
          edges {
            node {
              name
              slug
              menuItems {
                nodes {
                  label
                  url
                  target
                  cssClasses
                  menuItemId
                }
              }
            }
          }
        }
        legal: menus(where: { location: LEGAL }) {
          edges {
            node {
              name
              slug
              menuItems {
                nodes {
                  label
                  url
                  target
                  cssClasses
                  menuItemId
                }
              }
            }
          }
        }
      }
    }
  `)
}

import styled from 'styled-components'

import { GRID_UNIT, Grid, Text } from 'mf-ui-components'

import SiteLink from '../../common/SiteLink'

export const Container = styled(Grid).attrs({
  flow: 'column',
  gap: 'md',
})`
  min-width: ${GRID_UNIT * 20}px;

  > p {
    :hover {
      color: ${({ theme }) => theme.tokens.accent.base.primary.strong};
    }
  }
`

export const Link = styled(SiteLink)`
  color: ${({ theme }) => theme.tokens.accent.base.primary.regular};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.tokens.accent.base.primary.strong} !important;
  }

  &:visited {
    color: ${({ theme }) => theme.tokens.accent.base.primary.regular};
  }
`

export const LinkText = styled(Text).attrs({
  el: 'p',
  styleName: 'label',
})`
  color: ${({ theme }) => theme.tokens.accent.base.primary.regular};

  &:hover {
    color: ${({ theme }) => theme.tokens.accent.base.primary.strong};
  }

  &:visited {
    color: ${({ theme }) => theme.tokens.accent.base.primary.regular};
  }
`

export const List = styled(Grid).attrs({
  flow: 'column',
  gap: 'md',
})`
  font-size: 14px;
`

import styled from 'styled-components'

import { Grid, LinkButton } from 'mf-ui-components'

import { spacing } from '../../../utils/ui/spacing'
import { fromBreakpoint } from '../../../utils/style/breakpoint'
import { fontSize } from '../../../utils/style/fontSize'

export const FooterContainer = styled(Grid).attrs({
  flow: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  height: 100%;
  padding: 0 ${spacing('xs')} ${spacing('xs')} ${spacing('xs')};
  border-top: 1px solid ${({ theme }) => theme.tokens.interface.neutral.light};

  ${fromBreakpoint('lg')} {
    display: none !important;
  }
`

export const SignInButton = styled(LinkButton)`
  background: transparent;
  color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: content;

  svg {
    margin-right: ${spacing('xs')};
    transform: translateY(1px);
    fill: ${({ theme }) => theme.tokens.accent.base.secondary.regular};

    ${fromBreakpoint('md')} {
      margin-right: ${spacing('sm')};
    }
  }

  :hover:not(:disabled) {
    background: transparent;
    color: ${({ theme }) => theme.tokens.accent.token.secondary.strong};
  }
`

export const SignInButtonLabel = styled.span`
  ${fromBreakpoint('md')} {
    font-size: ${fontSize('normal')};
  }
`

export const PhoneNumberContainer = styled.a`
  color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const PhoneNumber = styled.span`
  margin-left: ${spacing('sm')};
  font-size: ${fontSize('normal')};
  text-decoration: underline;
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};

  ${fromBreakpoint('md')} {
    font-size: ${fontSize('big')};
  }
`

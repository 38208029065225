declare global {
  interface Window {
    dataLayer: (GTMEvent | GTMCustomData)[]
  }
}

interface EventData {
  category: string
  action: string
  label: string
}

interface GTMEvent {
  event: string
  event_data: EventData
  eventCallback?: (...args: any[]) => any
  eventTimeout?: number
  customFields?: Record<string, any>
}

type GTMCustomVariable = Record<string, string>

type GTMCustomObject = Record<string, Record<string, unknown>>

export type GTMCustomData = GTMCustomVariable | GTMCustomObject

export const pushToDataLayer = (event: GTMEvent | GTMCustomData) =>
  window.dataLayer && window.dataLayer.push(event)

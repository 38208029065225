import { Col } from 'styled-bootstrap-grid'
import styled, { css, CSSProperties } from 'styled-components'

import { Text, Theme } from 'mf-ui-components'

import SiteLink from '../common/SiteLink'
import { spacing } from '../../utils/ui/spacing'
import { fromBreakpoint, untilBreakpoint } from '../../utils/style/breakpoint'

function getFooterBackgroundColor(theme: Theme) {
  if (theme.id === 'mf-2023-light') return theme.tokens.space.tertiary.regular

  return theme.tokens.space.primary.regular
}

function getSectionBorderColor(theme: Theme) {
  if (theme.id === 'mf-2023-light')
    return theme.tokens.interface.neutral.regular

  return theme.tokens.interface.neutral.light
}

export const FooterWrapper = styled.footer`
  padding: ${spacing('3xl')} 0;
  background-color: ${({ theme }) => getFooterBackgroundColor(theme)};
  color: ${({ theme }) => theme.tokens.text.paragraph.regular};
  position: relative;
  z-index: 99;
  line-height: 22px;
`

export const Section = styled(Col)<{
  noBorder?: boolean
  style?: CSSProperties
}>`
  ${({ noBorder }) =>
    noBorder
      ? ''
      : css`
          border-bottom: 1px solid
            ${({ theme }) => getSectionBorderColor(theme)};
        `}
  display: flex;
  flex-flow: wrap row;
  padding: 50px 8px 40px;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-top: ${({ noBorder }) => (noBorder ? 0 : '50px')};
  }

  .tel-number {
    margin-top: 30px;
  }

  .copyright {
    font-family: ${({ theme }) => theme.typography.font.family.title};
    font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
    margin-bottom: ${spacing('sm')};
  }

  .office-info {
    font-size: 14px;
  }

  .contact-info {
    text-align: center;

    a {
      font-size: 16px;
      color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
      font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
      text-decoration: underlined;
    }
  }

  ${fromBreakpoint('md')} {
    padding: 50px 16px 40px;

    .tel-number {
      text-align: center;
    }

    .email-address {
      margin-top: 30px;
      text-align: center;
    }

    .copyright {
      text-align: center;
    }
  }

  ${fromBreakpoint('xl')} {
    .contact-info {
      text-align: left;
    }

    .tel-number,
    .email-address {
      margin-top: 0;
      text-align: right;
    }

    .copyright {
      text-align: left;
    }
  }
`

export const SubSection = styled(Col)`
  ${untilBreakpoint('lg')} {
    & + & {
      margin: 40px auto 26px;
    }
  }
`

export const StyledLink = styled.a`
  font-size: ${({ theme }) => theme.typography.font.sizes.normal}px;
  color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
  text-decoration: underlined;
`

export const ItemsWrapper = styled.ul`
  list-style: none;
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;
`

export const MenuItem = styled.li`
  padding: 5px 0;
`

export const MenuLink = styled(SiteLink)`
  color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.typography.font.weight.normal};
  text-decoration: underlined;
`

export const FSCSImage = styled.img`
  width: 96px;
  display: block;
  margin: 0 auto 10px;
`

export const SocialImage = styled.img`
  height: 45px;
  display: block;

  &:first-of-type {
    margin-right: ${spacing('sm')};
  }
`

export const ExternalLink = styled.a``

export const FooterPartnerParagraph = styled.p`
  font-size: 13px;
  line-height: 18px;

  ${fromBreakpoint('md')} {
    text-align: center;
  }

  ${fromBreakpoint('lg')} {
    text-align: left;
  }
`

export const FooterBottomNavigation = styled.div`
  padding: 15px 0;

  ul {
    list-style: none;
    text-align: center;
  }

  li {
    display: block;
    padding: 10px 0;

    a {
      color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
      font-size: ${({ theme }) => theme.typography.font.sizes.small}px;
      font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
      padding: 0 5px 0 2px;
      text-decoration: underlined;
    }
  }

  ${fromBreakpoint('md')} {
    ul {
      li {
        display: inline;

        &::after {
          content: '|';
        }

        &:last-of-type {
          &::after {
            content: '';
          }
        }
      }
    }
  }
`

export const SocialIcon = styled.a<{ src: string }>`
  width: 30px;
  height: 30px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  display: inline-block;

  & + & {
    margin-left: 30px;
  }
`

export const StyledSectionTitle = styled(Text)`
  margin-bottom: 15px;
`

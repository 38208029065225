import styled from 'styled-components'

import { LinkButton } from 'mf-ui-components'

import { spacing } from '../../utils/ui/spacing'
import { untilBreakpoint } from '../../utils/style/breakpoint'

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  gap: ${spacing('xs')};

  button {
    padding: 10px ${spacing('md')};
    line-height: 1;

    &:last-child {
      margin-right: 0;
    }
  }

  ${untilBreakpoint('lg')} {
    flex: 1;
    justify-content: flex-end;

    button,
    a {
      width: auto;
    }
  }
`

export const SignUpButton = styled(LinkButton)`
  background-color: ${({ theme }) => theme.tokens.accent.token.primary.regular};
  color: white;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.tokens.accent.token.primary.regular};

  :hover:not(:disabled) {
    background-color: ${({ theme }) =>
      theme.tokens.accent.token.primary.strong};
  }
`

export const SignInButton = styled(LinkButton)`
  && {
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.tokens.accent.token.secondary.strong};
    margin-left: ${spacing('xs')};
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: ${spacing('2xs')};
      transform: translateY(1px);
    }

    :hover:not(:disabled) {
      background: transparent;
      color: ${({ theme }) => theme.tokens.accent.token.secondary.strong};
    }
  }
`

import { getCookie } from '../cookies'

export type CookieConsent = 'granted' | 'denied'

export interface CookieOptions {
  consent_ad_storage: CookieConsent
  consent_analytics_storage: CookieConsent
  consent_personalization_storage: CookieConsent
}

export const getInitialCookies = (): CookieOptions => ({
  consent_ad_storage:
    getCookie('consent_ad_storage') === 'granted' ? 'granted' : 'denied',
  consent_analytics_storage:
    getCookie('consent_analytics_storage') === 'granted' ? 'granted' : 'denied',
  consent_personalization_storage:
    getCookie('consent_personalization_storage') === 'granted'
      ? 'granted'
      : 'denied',
})

import React from 'react'
import Hamburger from '../images/hamburger.svg'
import HamburgerInverted from '../images/hamburger-inverted.svg'

const HamburgerIcon = ({ inverted }) => (
  <img
    width="24"
    height="24"
    src={inverted ? HamburgerInverted : Hamburger}
    alt="hamburger-menu-icon"
  />
)

export default HamburgerIcon

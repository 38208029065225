import React, { FC } from 'react'

import rawlang, { contacts, navigationMenu } from '../../../utils/l10n'
import { Link, Row } from './ContactUsMenuItem.styles'

const lang = rawlang as 'it' | 'en'

export const ContactUsMenuItem: FC = () => (
  <Row>
    <Link to={'tel://'.concat(contacts.phone[lang])}>
      {navigationMenu.callUs[lang]}
    </Link>
    <Link to={contacts.email[lang]}>
      {navigationMenu.emailUs[lang]}
    </Link>
  </Row>
)

import styled, { css } from 'styled-components'

import {
  fromBreakpoint,
  untilBreakpoint,
} from '../../../utils/style/breakpoint'
import { spacing } from '../../../utils/ui/spacing'

function buildEntriesGridStyle(
  hasNestedChildren: boolean,
  childrenCount: number
) {
  if (!hasNestedChildren)
    return css`
      display: flex;
      flex-direction: column;
      gap: ${spacing('md')};
      min-width: 256px;
    `

  if (childrenCount <= 4)
    return css`
      display: grid;
      grid-template-columns: 160px 160px;
      gap: ${spacing('xl')};
    `

  return css`
    display: grid;
    grid-template-columns: 160px 160px 160px;
    gap: ${spacing('xl')} ${spacing('md')};
  `
}

export const EntriesContainer = styled.div<{
  hasNestedChildren: boolean
  childrenCount: number
  hasDescription: boolean
}>`
  background-color: ${({ theme }) => theme.tokens.space.surface.regular};
  border-radius: 8px;
  padding: ${spacing('md')};
  z-index: 1;

  ${({ hasNestedChildren, childrenCount }) =>
    buildEntriesGridStyle(hasNestedChildren, childrenCount)}

  ${fromBreakpoint('xl')} {
    ${({ hasDescription }) => hasDescription && 'min-height: 240px;'}
  }

  ${fromBreakpoint('xxl')} {
    padding: 16px;
    ${({ hasDescription }) => hasDescription && 'min-height: 280px;'}
  }
`

export const MenuItem = styled.div<{ tel: boolean }>`
  position: relative;
  transition: all 200ms ease-in-out;

  ${({ tel }) =>
    !!tel &&
    css`
      div {
        color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
        text-decoration: underline;

        &:hover {
          color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
        }
      }

      &:hover {
        background: none !important;
      }
    `}

  .child-menu {
    transform: translateY(32px) perspective(100px) scale(0.95) rotateX(2deg);
    opacity: 0;
    transition: all 400ms ease-in-out;
    filter: blur(4px);
  }

  &:hover {
    transition-duration: 100ms;
    background: ${({ theme }) => theme.tokens.space.tertiary.regular};

    .hover-zone {
      display: block;
    }

    .child-menu {
      visibility: visible;
      transform: translateY(8px) perspective(100px) scale(1) rotateX(0deg);
      opacity: 1;
      transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.175);
      filter: blur(0px);
    }

    .caret {
      transition-duration: 120ms;
      opacity: 0.2;
      transform: translateY(2px) scale(1.08);
    }
  }

  ${fromBreakpoint('lg')} {
    height: auto;

    &:hover {
      background: ${({ theme }) => theme.tokens.space.primary.regular};
    }
  }
`

export const Dropdown = styled.div<{ hasDescription: boolean }>`
  z-index: 100;
  box-shadow:
    0px 4px 16px rgba(0, 0, 0, 0.08),
    0px 2px 4px rgba(0, 0, 0, 0.08);
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  min-width: ${({ hasDescription }) =>
    hasDescription ? '315px' : 'fit-content'};
  border-radius: 12px;
  padding: ${spacing('2xs')};
  left: -48px;
  border: 1px solid #f3f2f3;
  background-color: ${({ theme }) => theme.tokens.space.primary.regular}F0;

  @supports (backdrop-filter: blur(24px)) {
    background-color: ${({ theme }) => theme.tokens.space.primary.regular}B3;
    backdrop-filter: blur(24px);
  }

  ${untilBreakpoint('lg')} {
    display: block;
    position: relative;
    box-shadow: none;
  }

  ${fromBreakpoint('xxl')} {
    padding: ${spacing('xs')};
  }
`

export const Rectangle = styled.div`
  display: none;
  width: 90%;
  height: 64px;
  position: absolute;
`

export const Triangle = styled.div`
  display: none;
  position: absolute;
  z-index: 100;
  transform: rotate(45deg);
  width: 64px;
  height: 64px;
  bottom: calc((64px / 1.1) * -1);
  right: -32px;

  ${fromBreakpoint('xl')} {
    width: 80px;
    height: 80px;
    bottom: calc((80px / 1.1) * -1);
    right: -40px;
  }

  ${fromBreakpoint('xxl')} {
    width: 128px;
    height: 128px;
    bottom: calc((128px / 1.1) * -1);
    right: -64px;
  }

  ${MenuItem}:not(:has(${MenuItem}:hover)) {
    pointer-events: none;
  }
`

import React, { FC } from 'react'

import { MenuNode } from '../types'
import { MobileMenuItem } from './MobileMenuItem'

type Props = {
  items: {
    node: MenuNode
  }[]
}
export const ItemsList: FC<Props> = ({ items }) =>
  items?.length > 0 ? (
    <>
      {items.map(({ node }, index) => (
        <MobileMenuItem key={'mobile' + index} node={node} />
      ))}
    </>
  ) : null

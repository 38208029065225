import { css } from 'styled-components'
import gridTheme from './gridTheme'

const breakpoints = gridTheme.breakpoints

/**
 * A helper function to handle media queries in styled components
 * @param {str} breakpoint xs | sm | md | lg | xl | xxl
 * @param {str} range min | max
 */
export default function media(breakpoint, range = 'min', debug = false) {
  if (!breakpoints.hasOwnProperty(breakpoint))
    return () => {
      return `&:before { content: "Breakpoint not supported. Accepted values: xs, sm, md, lg, xl."; color: red; }`
    }

  const numberValue =
    range === 'min' ? breakpoints[breakpoint] : breakpoints[breakpoint] - 1

  return function(str, ...args) {
    if (debug) console.log(args)

    return `@media screen and (${range}-width: ${numberValue}px) {
            ${css(str, ...args).join('')}
        }`
  }
}

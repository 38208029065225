import React, { useEffect, useState } from 'react'

export function useMediaQuery(...queries: string[]): boolean {
  const supportMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'

    // NOTE: This forces the hook to rerun on the client, since it sometimes
    // glitches, since gatsby doesn't throw when trying to use `useEffect` on
    // the server side, but rather skips its execution.
  const [clientSideRun, setClientSideRun] = useState<boolean>(false)
  const [match, setMatch] = useState<boolean>(false)

  const query = queries.join()

  useEffect(() => {
    if (!supportMatchMedia) return undefined

    setClientSideRun(true)
  }, [supportMatchMedia])

  useEffect(() => {
    let active = true

    if (!supportMatchMedia) return undefined

    const queryList = window.matchMedia(query)

    function updateMatch() {
      if (active) setMatch(queryList.matches)
    }

    updateMatch()

    queryList.addEventListener('change', updateMatch)

    return () => {
      active = false
      queryList.removeEventListener('change', updateMatch)
    }
  }, [query, supportMatchMedia, clientSideRun])

  return match
}

import React, { FC } from 'react'

import { SiteLogo } from '../SiteLogo'
import { SiteNavigation } from '../SiteNavigation'
import { MenuNode } from '../SiteNavigation/types'
import { SiteNavigationButtonArea } from '../SiteNavigationButtonArea'
import { Cookies } from '../Cookies'
import { FullHeader, HeaderTag, Wrapper } from './Header.styles'

type Props = {
  menuVersion: number
  menu: { node: MenuNode }[]
}

export const Header: FC<Props> = ({ menu, menuVersion }) => (
  <FullHeader>
    <Cookies />
    <HeaderTag id="global">
      <Wrapper className="header-wrapper">
        <SiteLogo />

        <SiteNavigation menu={menu} />
        <SiteNavigationButtonArea />
      </Wrapper>
    </HeaderTag>
  </FullHeader>
)

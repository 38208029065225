import styled from 'styled-components'

import { spacing } from '../../utils/ui/spacing'

export const Container = styled.div<{ backgroundColour: string }>`
  width: 100%;
  background-color: ${({ backgroundColour }) => backgroundColour};
  padding: ${spacing('md')} ${spacing('xl')};
  z-index: 100;
  position: sticky;
  text-align: center;
  top: 0;
`

export const Content = styled.span<{ foregroundColour: string }>`
  font-size: 18px;
  font-family: ${({ theme }) => theme.typography.font.family.text};
  color: ${({ foregroundColour }) => foregroundColour};

  a {
    text-decoration: underline;
    color: ${({ foregroundColour }) => foregroundColour};
  }
`

import React from 'react'

import { Icon } from 'mf-ui-components'

import rawlang, * as l10n from '../../utils/l10n'
import {
  ButtonArea,
  SignInButton,
  SignUpButton,
} from './SiteNavigationButtonArea.styles'

const lang = rawlang as 'it' | 'en'
const mfAppUrl = process.env.GATSBY_MF_APP_URL
const mfAppCountry = process.env.GATSBY_COUNTRY?.toLowerCase()

const SIGN_UP_URL = {
  IT: 'https://gsxp.adj.st/sign-up?adj_t=1cm3gkkr&adj_engagement_type=fallback_click&adj_fallback=https%3A%2F%2Fapp.moneyfarm.com%2Fit%2Fgetstarted%3Fvariant%3Dflat&adj_redirect_macos=https%3A%2F%2Fapp.moneyfarm.com%2Fit%2Fgetstarted%3Fvariant%3Dflat',
  GB: `${mfAppUrl}/${mfAppCountry}/getstarted?variant=multistep`,
}

export const SiteNavigationButtonArea = () => (
  <ButtonArea>
    <SignUpButton
      size="small"
      href={SIGN_UP_URL[process.env.GATSBY_COUNTRY as 'IT' | 'GB']}
      referrerPolicy="no-referrer-when-downgrade"
    >
      {l10n.get_started[lang || 'en']}
    </SignUpButton>

    <SignInButton size="small" href={`${mfAppUrl}/${mfAppCountry}/sign-in`}>
      <Icon size={20} type="icon-account" />
      <span>{l10n.sign_in[lang || 'en']}</span>
    </SignInButton>
  </ButtonArea>
)

import { Accordion, Theme } from 'mf-ui-components'
import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { useIsViewportBelow } from '../../../utils/useIsViewportBelow'
import gridTheme from '../../../utils/gridTheme'

type Props = {
  title: string
  nestingLevel?: number
}

export const MobileMenuAccordion: FC<PropsWithChildren<Props>> = ({
  title,
  nestingLevel = 0,
  children,
}) => {
  const [isRevealed, setRevealed] = useState<boolean>(false)
  const [fontSize, setFontSize] = useState<string>('20px')
  const [accordionMargin, setAccordionMargin] = useState<string>()

  const theme: Theme = useTheme() as Theme
  const isSmartphone: boolean = useIsViewportBelow(gridTheme.breakpoints.md)
  const isTablet: boolean = useIsViewportBelow(gridTheme.breakpoints.lg)

  const isNested: boolean = nestingLevel > 0

  useEffect(() => {
    setFontSize(() => {
      if (isSmartphone) return `${theme.typography.font.sizes.normal}px`

      if (isTablet) return `${theme.typography.font.sizes.normal}px`

      return isNested ? `${theme.typography.font.sizes.big}px` : '20px'
    })

    setAccordionMargin(() => {
      if (!isSmartphone && !isNested) return theme.tokens.spacing.xs

      return undefined
    })
  }, [isSmartphone, isNested])

  return (
    <Accordion
      title={title}
      onClick={() => setRevealed(currentState => !currentState)}
      isOpen={isRevealed}
      titleTextStyles={{
        fontSize,
        fontWeight: 700,
        color: theme.tokens.accent.token.secondary.regular,
        padding: `0 ${theme.tokens.spacing.md}`,
        textTransform: !isNested ? 'uppercase' : undefined,
        // Add extra margin to even out spacing on tablets.
        margin: accordionMargin,
      }}
      fullWidth
    >
      {children as JSX.Element}
    </Accordion>
  )
}

import React, { FC } from 'react'

import { Grid } from 'mf-ui-components'

import { MenuNode } from '../types'
import { NavigationBarLink } from './NavigationBarLink'
import { DescriptionBox } from './DescriptionBox'
import { MenuEntry } from './MenuEntry'
import {
  EntriesContainer,
  MenuItem,
  Dropdown,
  Triangle,
  Rectangle,
} from './DesktopMenuItem.styles'

const isTel = (url: string) => url.startsWith('tel://')

type Props = { node: MenuNode }

export const DesktopMenuItem: FC<Props> = ({ node }) => {
  const childrenCount = node.childItems.nodes.length
  const hasChildren = childrenCount > 0
  const hasNestedChildren =
    hasChildren &&
    node.childItems.nodes.some(({ childItems }) => !!childItems?.nodes?.length)
  const hasDescription = !!node.description?.length

  return (
    <MenuItem tel={isTel(node.url)}>
      <NavigationBarLink node={node} hasChildren={hasChildren} />

      {hasChildren && (
        <>
          <Rectangle className="hover-zone" />
          <Triangle className="hover-zone" />

          <Dropdown className="child-menu" hasDescription={hasDescription}>
            <Grid flow="row" gap="md" alignItems="stretch">
              {hasDescription && (
                <DescriptionBox
                  title={node.label}
                  description={node.description}
                />
              )}

              <EntriesContainer
                hasNestedChildren={hasNestedChildren}
                hasDescription={hasDescription}
                childrenCount={childrenCount}
              >
                {node.childItems.nodes.map((child, index) => (
                  <MenuEntry key={index} {...child} />
                ))}
              </EntriesContainer>
            </Grid>
          </Dropdown>
        </>
      )}
    </MenuItem>
  )
}

import React from 'react'
import { Container, Row } from 'styled-bootstrap-grid'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { marginBreakpoints, hideBlockAt } from '../../../utils/commonAttrs'

const Cont = styled(Container)`
  ${props => marginBreakpoints(props, 0)}
  ${props => hideBlockAt(props)}
`

export default function BootstrapColumns({
  id,
  marginBottom,
  hiddenAt,
  children,
  alignItems = 'stretch',
  justifyContent = 'center',
  fullWidth,
  noGutters,
}) {
  const gutters = noGutters ? { paddingLeft: '0px', paddingRight: '0px' } : {}
  return (
    <Cont
      id={id}
      marginBottom={marginBottom}
      hiddenAt={hiddenAt}
      className="container"
      fluid={fullWidth}
      style={gutters}
    >
      <Row
        className="row"
        justifyContent={justifyContent}
        alignItems={alignItems}
      >
        {children}
      </Row>
    </Cont>
  )
}

export const query = graphql`
  fragment columnsFields on WPGraphQL_MfblocksBsColumnsBlock {
    name
    attributes {
      alignItems
      justifyContent
      columns
      fullWidth
      noGutters
      hiddenAt
      marginBottom
      id
    }
    innerBlocks {
      ...columnFields
    }
  }
  fragment nestedColumnsFields on WPGraphQL_MfblocksNestedColumnsBlock {
    name
    attributes {
      alignItems
      justifyContent
      columns
      fullWidth
      noGutters
      hiddenAt
      marginBottom
      id
    }
    innerBlocks {
      ...nestedColumnFields
    }
  }
`

import Cookies from 'js-cookie'

export const setCookie = (
  name: string,
  value: string,
  options?: Cookies.CookieAttributes
): string | undefined => Cookies.set(name, value, options)

export const getCookie = (name: string): string | undefined => Cookies.get(name)

export const getCookies = (): { [key: string]: string } => Cookies.get()

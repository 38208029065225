import React, { FC } from 'react'

import { Text } from 'mf-ui-components'

import { MenuNode } from '../types'
import { Container, Link, LinkText, List } from './MenuEntry.styles'

type Props = MenuNode

export const MenuEntry: FC<Props> = ({ childItems, label, url }) =>
  !childItems.nodes?.length ? (
    <Link to={url}>
      <LinkText>{label}</LinkText>
    </Link>
  ) : (
    <Container>
      <Text el="p" styleName="label">
        {label}
      </Text>
      <List>
        {childItems.nodes.map(({ url, label }) => (
          <Link key={label} to={url}>
            {label}
          </Link>
        ))}
      </List>
    </Container>
  )

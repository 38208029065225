import styled from 'styled-components'

import { GRID_UNIT } from 'mf-ui-components'

import SiteLink from '../../common/SiteLink'
import { spacing } from '../../../utils/ui/spacing'
import { fromBreakpoint } from '../../../utils/style/breakpoint'
import { fontSize } from '../../../utils/style/fontSize'

export const Container = styled.div<{ nestingLevel: number }>`
  padding-right: ${spacing('md')};
  margin-left: ${({ nestingLevel }) =>
    nestingLevel > 0 ? `${GRID_UNIT * (nestingLevel + 1)}px` : 0};

  ${({ nestingLevel }) =>
    nestingLevel > 0
      ? `
          margin-bottom: ${GRID_UNIT * 2 - 2}px;
          margin-top: -${spacing('2xs')};

          ${fromBreakpoint('md')} {
            margin-bottom: 28px;
          }
        `
      : ''}
`

export const Separator = styled.hr`
  width: 100%;
  opacity: 0.2;
`

export const ChildElement = styled(SiteLink)`
  display: block;
  margin-left: ${spacing('xl')};
  margin-bottom: ${spacing('md')};
  color: ${({ theme }) => theme.tokens.accent.base.primary.regular};
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
  cursor: pointer;
  text-decoration: none;
  font-size: ${fontSize('normal')};

  &:first-child {
    margin-top: ${spacing('md')};
  }

  ${fromBreakpoint('md')} {
    font-size: ${fontSize('big')};
    margin-bottom: ${spacing('xl')};

    &:first-child {
      margin-top: ${spacing('lg')};
    }
  }
`

export const ChildlessItem = styled(SiteLink)`
  font-size: ${fontSize('normal')};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
  color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
  display: block;
  margin-bottom: ${spacing('xs')};
  margin-top: ${spacing('xs')};
  cursor: pointer;
  text-decoration: none;
  padding: 0 ${spacing('md')};
  padding-bottom: ${spacing('md')};

  ${fromBreakpoint('md')} {
    margin-left: ${spacing('xs')};
  }

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ theme }) => theme.tokens.interface.neutral.light};
  }
`

export const SecondaryHeading = styled.span`
  margin-top: ${spacing('lg')};
  margin-bottom: ${spacing('xs')};
  display: block;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.tokens.accent.base.primary.regular};
`

import styled from 'styled-components'

import { spacing } from '../../utils/ui/spacing'

export const Button = styled.button<{ foregroundColour: string }>`
  cursor: pointer;
  border: 2px solid ${({ foregroundColour }) => foregroundColour};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: ${spacing('xl')};
  position: absolute;
  right: ${spacing('md')};
  margin: auto 0;
  top: 0;
  bottom: 0;
  background: none;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    > path {
      stroke: ${({ foregroundColour }) => foregroundColour};
    }
  }
`

import styled from 'styled-components'

import { GRID_UNIT, Icon, Theme } from 'mf-ui-components'

import gridTheme from '../../../utils/gridTheme'
import SiteLink from '../../common/SiteLink'
import { spacing } from '../../../utils/ui/spacing'
import { untilBreakpoint } from '../../../utils/style/breakpoint'
import { fontSize } from '../../../utils/style/fontSize'

export const StyledLink = styled(SiteLink)<{
  hasChildren?: boolean
}>`
  text-decoration: none;
  overflow: hidden;

  ${untilBreakpoint('lg')} {
    ${({ hasChildren }) => (hasChildren ? 'pointer-events: none;' : '')}
  }
`

export const LinkWrapper = styled.div<{
  hasChildren?: boolean
  isTel?: boolean
}>`
  display: flex;
  align-items: center;
  padding: ${spacing('lg')} ${spacing('md')};
  height: 100%;
  background: inherit;

  @media screen and (min-width: ${
    gridTheme.breakpoints.lg
  }px) and (max-width: ${gridTheme.breakpoints.xl}px) {
    padding: ${spacing('lg')} ${spacing('xs')};
  }

  ${untilBreakpoint('lg')} {
    ${({ hasChildren }) =>
      hasChildren
        ? `
            padding: 13px 0;
            text-transform: uppercase;
            margin-top: 15px;
          `
        : `
            padding: 10px;
            margin-top: 20px;
          `}

    ${({ isTel, hasChildren }) =>
      !isTel && !hasChildren
        ? `
            &:hover {
              background: ${({ theme }: { theme: Theme }) =>
                theme.tokens.space.tertiary.regular};
              color: ${({ theme }: { theme: Theme }) =>
                theme.tokens.accent.base.primary.strong} !important;
            }
          `
        : ''}

    ${({ isTel }) => (isTel ? 'margin-top: 0px !important;' : '')}
  }
`

export const LinkBody = styled.div<{ hasChildren?: boolean }>`
  text-align: center;
  position: relative;
  line-height: 1;
  color: ${({ theme }) => theme.tokens.accent.base.primary.regular};
  font-size: ${fontSize('normal')};
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};

  ${untilBreakpoint('lg')} {
    text-align: left;
    color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
    font-size: 14px;
  }
`

export const Caret = styled(Icon).attrs(({ theme }) => ({
  className: 'caret',
  type: 'icon-chevron-down-big',
  size: 10,
  color: theme.tokens.accent.base.primary.regular,
}))<{ hasChildren?: boolean; isTel?: boolean }>`
  display: block;
  position: absolute;
  bottom: -${2 * GRID_UNIT}px;
  left: 50%;
  opacity: 0.4;
  transition: all 400ms ease-in-out;

  ${({ isTel }) => (isTel ? 'display: none;' : '')}

  ${({ hasChildren }) => (!hasChildren ? 'visibility: hidden;' : '')}
`

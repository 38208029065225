import styled from 'styled-components'

import { textLinkStyle } from '../common/textLinkStyle'
import { spacing } from '../../utils/ui/spacing'

export const Main = styled.main`
  padding-top: ${spacing('4xl')};
  overflow: hidden;
  background: ${({ theme }) => theme.tokens.space.secondary.regular};

  p a {
    ${textLinkStyle}
  }

  small,
  sub,
  sup {
    font-size: 87% !important;
  }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
    font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
    text-decoration: none;
  }
  h4 {
    letter-spacing: 0;
  }

  [data-cy-label='modal'] {
    overflow-y: scroll;
  }
`

import { Ref } from 'react'
import styled from 'styled-components'

import { fromBreakpoint, untilBreakpoint } from '../../utils/style/breakpoint'
import { spacing } from '../../utils/ui/spacing'

export const NavWrapper = styled.div<{ ref: Ref<HTMLElement> }>`
  display: flex;

  ${untilBreakpoint('lg')} {
    order: 2;
  }
`

export const FixedWrapper = styled.div`
  display: flex;

  ${untilBreakpoint('lg')} {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: ${({ theme }) => theme.tokens.space.secondary.regular};

    &.openMenu {
      overflow: hidden;
      display: block;
      width: 100%;
      height: 100vh;
      height: -webkit-fill-available;
      display: grid;
      grid-template-rows: 64px 1fr 48px;
      z-index: 90;
      box-sizing: border-box;

      /* No extra padding needed on Safari mobile. */
      @supports (-webkit-touch-callout: none) {
        grid-template-rows: 64px 1fr 64px;
      }
    }
  }
`

export const Backdrop = styled.div`
  display: none;

  ${untilBreakpoint('lg')} {
    display: none;
    position: fixed;
    top: 0;
    left: 0;

    &.openMenu {
      display: block;
      z-index: 80;
      background-color: ${({ theme }) => theme.tokens.space.secondary.regular};
      height: 100vh;
      width: 100vw;
    }
  }
`

export const ItemsWrapper = styled.div`
  display: flex;

  ${untilBreakpoint('lg')} {
    display: none;

    &.openMenu {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
    }

    .topLevel.hasChildren {
      margin-top: 15px;

      &:first-child {
        margin-top: 0;
      }
    }

    .caret {
      display: none;
    }
  }
`

export const MobileHeading = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-bottom: ${spacing('xl')};
  padding: ${spacing('md')} ${spacing('sm')};

  ${fromBreakpoint('lg')} {
    display: none;
  }
`

export const Logotype = styled.img`
  user-select: none;
  height: 28px;
  position: relative;
  top: 2px;
  left: -1px;
`

import styled from 'styled-components'

import gridTheme from '../../utils/gridTheme'
import { WEBSITE_MAX_WIDTH } from '../../utils/siteMaxWidth'
import { fromBreakpoint } from '../../utils/style/breakpoint'
import { spacing } from '../../utils/ui/spacing'

export const FullHeader = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9090;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.interface.neutral.light};

  @media screen and (min-width: ${WEBSITE_MAX_WIDTH}) and (orientation: landscape) {
    max-width: ${WEBSITE_MAX_WIDTH};
    border-left: 1px solid
      ${({ theme }) => theme.tokens.interface.neutral.strong};
    border-right: 1px solid
      ${({ theme }) => theme.tokens.interface.neutral.strong};
  }
`

export const HeaderTag = styled.header`
  display: flex;
  justify-content: center;
  height: 64px;
  background: ${({ theme }) => theme.tokens.space.secondary.regular};

  ${fromBreakpoint('xl')} {
    height: 76px;
  }
`

export const Wrapper = styled.div`
  max-width: 100%;
  width: ${gridTheme.container.maxWidth.xl}px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 0;

  ${fromBreakpoint('lg')} {
    padding: 0 ${spacing('md')};
  }
`

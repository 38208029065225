import media from './media'
import gridTheme from './gridTheme'

export const marginBreakpoints = ({ marginBottom }, defaultValue = 24) => {
  if (!marginBottom || marginBottom === '[]')
    return `margin-bottom: ${defaultValue}px;`

  if (marginBottom === 0) return `margin-bottom: 0px;`

  const margin = JSON.parse(marginBottom)
  const { xs, sm, md, lg, xl } = margin

  return `
        margin-bottom: ${
          xs === 0 ? 0 : xs > 0 && xs !== null ? xs : defaultValue
        }px;
        ${
          sm || sm === 0
            ? `@media screen and (min-width: ${gridTheme.breakpoints.sm}px) {
            margin-bottom: ${sm}px;
        }`
            : ``
        }
        ${
          md || md === 0
            ? `@media screen and (min-width: ${gridTheme.breakpoints.md}px) {
            margin-bottom: ${md}px;
        }`
            : ``
        }
        ${
          lg || lg === 0
            ? `@media screen and (min-width: ${gridTheme.breakpoints.lg}px) {
            margin-bottom: ${lg}px;
        }`
            : ``
        }
        ${
          xl || xl === 0
            ? `@media screen and (min-width: ${gridTheme.breakpoints.xl}px) {
            margin-bottom: ${xl}px;
        }`
            : ``
        }
    `
}

export function hideBlockAt({ hiddenAt }) {
  const hidden =
    hiddenAt !== 'false' && hiddenAt !== undefined
      ? JSON.parse(hiddenAt)
      : false

  if (hidden) {
    const { xs, sm, md, lg, xl } = hidden

    return `
            ${xs === false ? `display: none;` : ``}
            ${
              sm === false
                ? `@media screen and (min-width: ${gridTheme.breakpoints.sm}px) {display: none;}`
                : `${
                    sm === true
                      ? `@media screen and (min-width: ${gridTheme.breakpoints.sm}px) {display: block;}`
                      : ``
                  }`
            }
            ${
              md === false
                ? `@media screen and (min-width: ${gridTheme.breakpoints.md}px) {display: none;}`
                : `${
                    md === true
                      ? `@media screen and (min-width: ${gridTheme.breakpoints.md}px) {display: block;}`
                      : ``
                  }`
            }
            ${
              lg === false
                ? `@media screen and (min-width: ${gridTheme.breakpoints.lg}px) {display: none;}`
                : `${
                    lg === true
                      ? `@media screen and (min-width: ${gridTheme.breakpoints.lg}px) {display: block;}`
                      : ``
                  }`
            }
            ${
              xl === false
                ? `@media screen and (min-width: ${gridTheme.breakpoints.xl}px) {display: none;}`
                : `${
                    xl === true
                      ? `@media screen and (min-width: ${gridTheme.breakpoints.xl}px) {display: block;}`
                      : ``
                  }`
            }
        `
  }
}

/**
 * The following is for the Column block only
 */
const alignmentValue = value => {
  const values = {
    top: 'flex-start',
    middle: 'center',
    bottom: 'flex-end',
  }

  if (value in values) return values[value]

  return 'flex-start'
}

const fallback = (value, breakpoint) => {
  if (value === 'top') {
    const backToNormal = `display: block;`

    return breakpoint === 'xs' ? backToNormal : media(breakpoint)(backToNormal)
  }

  return ``
}
export function columnVerticalAlign(verticalAlign) {
  if (!verticalAlign) return ``

  const { xs, sm, md, lg, xl } = JSON.parse(verticalAlign)

  return `
        ${
          xs !== 'top' && xs
            ? `display: flex; flex-flow: nowrap column; width: 100%; justify-content: ${alignmentValue(
                xs
              )};`
            : fallback(xs, 'xs')
        }
        ${
          sm !== 'top' && sm
            ? media(
                'sm'
              )`display: flex; flex-flow: nowrap column; width: 100%; justify-content: ${alignmentValue(
                sm
              )};`
            : fallback(sm, 'sm')
        }
        ${
          md !== 'top' && md
            ? media(
                'md'
              )`display: flex; flex-flow: nowrap column; width: 100%; justify-content: ${alignmentValue(
                md
              )};`
            : fallback(md, 'md')
        }
        ${
          lg !== 'top' && lg
            ? media(
                'lg'
              )`display: flex; flex-flow: nowrap column; width: 100%; justify-content: ${alignmentValue(
                lg
              )};`
            : fallback(lg, 'lg')
        }
        ${
          xl !== 'top' && xl
            ? media(
                'xl'
              )`display: flex; flex-flow: nowrap column; width: 100%; justify-content: ${alignmentValue(
                xl
              )};`
            : fallback(xl, 'xl')
        }
    `
}

export function columnHorizontalAlign(horizontalAlign) {
  if (!horizontalAlign) return ``

  const { xs, sm, md, lg, xl } = JSON.parse(horizontalAlign)

  return `
    ${xs ? `text-align: ${xs}; justify-content: ${xs};` : ''}
    ${sm ? media('sm')`text-align: ${sm}; justify-content: ${sm};` : ''}
    ${md ? media('md')`text-align: ${md}; justify-content: ${md};` : ''}
    ${lg ? media('lg')`text-align: ${lg}; justify-content: ${lg};` : ''}
    ${xl ? media('xl')`text-align: ${xl}; justify-content: ${xl};` : ''}
  `
}

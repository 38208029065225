import styled from 'styled-components'

import { Grid, Text } from 'mf-ui-components'

import { spacing } from '../../../utils/ui/spacing'
import { serifFont } from '../../../utils/style/serifFont'
import { fromBreakpoint } from '../../../utils/style/breakpoint'

export const Container = styled(Grid).attrs({
  flow: 'column',
  gap: 'md',
})`
  && {
    margin: 0 ${spacing('md')};
    padding: ${spacing('xs')} 0;
    width: 140px;

    ${fromBreakpoint('xxl')} {
      margin: 0 ${spacing('lg')};
      padding: ${spacing('md')} 0;
      width: 175px;
    }
  }
`

export const Title = styled(Text).attrs({
  el: 'h6',
  styleName: 'title5',
})`
  margin-top: -${spacing('2xs')};
  margin-bottom: ${spacing('2xs')};
  ${serifFont}
`

import { css } from 'styled-components'

export const textLinkStyle = css`
  text-decoration: underline;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
  color: ${({ theme }) => theme.tokens.accent.base.secondary.regular};

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.tokens.accent.base.secondary.strong};
  }

  svg,
  use {
    fill: ${({ theme }) => theme.tokens.accent.base.secondary.regular};
  }

  svg:hover,
  use:hover {
    fill: ${({ theme }) => theme.tokens.accent.base.secondary.strong};
  }
`

export const normalizeHexColourCode = color => {
  const hexArray = color
    .toUpperCase()
    .replace('#', '')
    .split('')

  return hexArray.length === 3
    ? `#${hexArray.flatMap(c => [c, c]).join('')}`
    : color.toUpperCase()
}

import { Grid, GridItem } from 'mf-ui-components/build/es/src'
import React, { FC } from 'react'

import parser from '../../utils/htmlParser'
import { FooterProps } from './Footer'
import {
  Section,
  StyledSectionTitle,
  MenuItem,
  MenuLink,
  ItemsWrapper,
} from './Footer.styles'

type Props = Pick<FooterProps, 'menu'> & {
  copyright: string
  registered: string
}

export const Menu: FC<Props> = ({ menu, copyright, registered }) => (
  <Section>
    <Grid width="12" gap="5xl" flow={{ sm: 'column', lg: 'row' }}>
      <GridItem width={{ sm: 12, lg: 4 }}>
        <div className="copyright">{copyright}</div>
        <div className="office-info" style={{ marginBottom: '50px' }}>
          {registered}
        </div>
      </GridItem>

      <Grid
        width={{ sm: 12, lg: 8 }}
        flow={{ sm: 'column', md: 'row' }}
        gap="sm"
      >
        {menu.map((menu, index) => (
          <GridItem key={index} width={{ sm: 12, md: 6, lg: 3 }}>
            {/* @ts-expect-error */}
            <ItemsWrapper index={index}>
              <StyledSectionTitle styleName="title7" el="h5">
                {menu.name}
              </StyledSectionTitle>
              {menu.menuItems.nodes.map((item, itemIndex) => (
                <MenuItem key={itemIndex}>
                  <MenuLink to={item.url}>{parser(item.label)}</MenuLink>
                </MenuItem>
              ))}
            </ItemsWrapper>
          </GridItem>
        ))}
      </Grid>
    </Grid>
  </Section>
)

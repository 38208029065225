import styled from 'styled-components'

import SiteLink from '../../common/SiteLink'
import { spacing } from '../../../utils/ui/spacing'
import { fromBreakpoint } from '../../../utils/style/breakpoint'
import { fontSize } from '../../../utils/style/fontSize'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: ${spacing('xs')} ${spacing('md')};
  margin-bottom: ${spacing('xs')};

  ${fromBreakpoint('lg')} {
    padding: ${spacing('2xs')} ${spacing('xl')};
  }
`

export const Link = styled(SiteLink)`
  color: ${({ theme }) => theme.tokens.accent.base.primary.regular};
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
  text-decoration: underline;
  cursor: pointer;

  :first-child {
    margin-right: ${spacing('md')};

    ${fromBreakpoint('lg')} {
      margin-right: ${spacing('xl')};
    }
  }

  ${fromBreakpoint('lg')} {
    font-size: ${fontSize('normal')};
  }
`

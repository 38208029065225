import { GTMCustomData, pushToDataLayer } from '../../services/GTMService'
import { getCookie, setCookie } from '../cookies'
import { CookieConsent, CookieOptions } from './data'

const COOKIE_DOMAIN = '.moneyfarm.com'
const isGrantedCookie = (value: CookieConsent): boolean => value === 'granted'

const cookiesGranted = (cookies: CookieOptions): boolean =>
  Object.values(cookies).every(isGrantedCookie)

export const cookiesConsentExpressed = (cookies: CookieOptions): boolean => {
  const allGranted = cookiesGranted(cookies)

  if (allGranted) return true

  return getCookie('consent_updated')
    ? getCookie('consent_updated') === 'true'
    : false
}

export const updateCookiesConsent = (
  cookieOptions: CookieOptions,
  grantAll?: boolean
): void => {
  setCookie('consent_updated', 'true', { domain: COOKIE_DOMAIN })

  const event: GTMCustomData = {
    event: 'cookie_consent_updated',
    ad_storage: grantAll ? 'granted' : cookieOptions.consent_ad_storage,
    analytics_storage: grantAll
      ? 'granted'
      : cookieOptions.consent_analytics_storage,
    personalization_storage: grantAll
      ? 'granted'
      : cookieOptions.consent_personalization_storage,
  }

  pushToDataLayer(event)
}

import classnames from 'classnames'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

import 'animate.css'

const AnimateOnScrollWrapper = styled.div`
  /* Overriding animation options that are not provided by animate.css library. */
  ${({ animationDelay }) =>
    animationDelay ? `animation-delay: ${animationDelay}ms;` : ''}
  ${({ animationEasing }) =>
    animationEasing
      ? `animation-timing-function: ${animationEasing};`
      : ''}

  .animated-element-hidden {
    visibility: hidden;
  }

  .animated-element-visible {
    visibility: visible;
  }
`

const AnimateOnScroll = ({
  threshold = 0,
  triggerOnce = true,
  animationType,
  animationDelay,
  animationEasing,
  children,
}) => {
  const [ref, inView] = useInView({ threshold, triggerOnce })

  return (
    <AnimateOnScrollWrapper
      className={classnames(
        'animate__animated',
        inView && `animate__${animationType}`
      )}
      animationDelay={animationDelay}
      animationEasing={animationEasing}
      ref={ref}
    >
      <div
        className={classnames(
          'animated-element-hidden',
          inView && `animated-element-visible`
        )}
      >
        {children}
      </div>
    </AnimateOnScrollWrapper>
  )
}

export default AnimateOnScroll

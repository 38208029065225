import { locale } from '../env'
import enGB from './en-GB.json'
import itIT from './it-IT.json'

export const i18n = () => {
  switch (locale) {
    case 'en-GB': {
      return enGB
    }
    case 'it-IT': {
      return itIT
    }
  }
}

import React, { FC } from 'react'
import { useTheme } from 'styled-components'

import { Theme } from 'mf-ui-components'

import parser from '../../utils/htmlParser'
import ParagraphBlock from '../blocks/core/Paragraph'
import { Section } from './Footer.styles'
import { FooterContent } from './types'
import { spacing } from '../../utils/ui/spacing'

type Props = Pick<FooterContent, 'disclaimer'>

export const Disclaimer: FC<Props> = ({ disclaimer }) => {
  const theme = useTheme() as Theme

  return (
    <Section
      style={{
        textAlign: 'center',
      }}
    >
      {/*  @ts-expect-error - Since this is a JS component, all props are marked as mandatory even if they are actually not. */}
      <ParagraphBlock
        customTextColor={theme.tokens.text.paragraph.regular}
        style={{ padding: spacing('md') }}
      >
        {parser(disclaimer)}
      </ParagraphBlock>
    </Section>
  )
}

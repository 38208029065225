import React, { FC } from 'react'
import { Col, Row } from 'styled-bootstrap-grid'

import parser from '../../utils/htmlParser'
import {
  FooterPartnerParagraph,
  FSCSImage,
  Section,
  SubSection,
} from './Footer.styles'
import { FooterContent } from './types'

type Props = { legalSections: FooterContent['legal']['section'] }

export const Legal: FC<Props> = ({ legalSections }) => (
  <Section noBorder>
    {!!legalSections?.length &&
      legalSections.map((section, index) => (
        <SubSection key={index} col={12} lg={12 / legalSections.length}>
          <Row>
            <Col col={12} lg={4}>
              <FSCSImage src={section.image.mediaItemUrl} loading="lazy" />
            </Col>
            <Col col={12} lg={8}>
              <FooterPartnerParagraph>
                {parser(section.text)}
              </FooterPartnerParagraph>
            </Col>
          </Row>
        </SubSection>
      ))}
  </Section>
)

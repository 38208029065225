import React, { FC } from 'react'

import { Icon } from 'mf-ui-components'

import gridTheme from '../../../utils/gridTheme'
import rawlang, * as l10n from '../../../utils/l10n'
import { useIsViewportBelow } from '../../../utils/useIsViewportBelow'
import {
  FooterContainer,
  PhoneNumberContainer,
  PhoneNumber,
  SignInButton,
  SignInButtonLabel,
} from './MobileFooter.styles'

const lang = rawlang as 'it' | 'en'
const mfAppUrl = process.env.GATSBY_MF_APP_URL
const mfAppCountry = process.env.GATSBY_COUNTRY?.toLowerCase()

export const MobileFooter: FC = () => {
  const isSmartphone: boolean = useIsViewportBelow(gridTheme.breakpoints.md)

  return (
    <FooterContainer>
      <SignInButton href={`${mfAppUrl}/${mfAppCountry}/sign-in`}>
        <Icon
          size={isSmartphone ? 20 : 28}
          type="icon-account"
        />
        <SignInButtonLabel>{l10n.sign_in[lang || 'en']}</SignInButtonLabel>
      </SignInButton>

      <PhoneNumberContainer>
        <Icon
          size={isSmartphone ? 18 : 20}
          type="icon-phone"
        />
        <PhoneNumber>{l10n.contacts.phone[lang || 'en']}</PhoneNumber>
      </PhoneNumberContainer>
    </FooterContainer>
  )
}

import React, { FC } from 'react'
import { Link } from 'gatsby'

import { countryPrefix, wpUrl } from '../../utils/env'
import { sanitiseLink } from '../../utils/sanitiseLink'

type Props = { to: string; className?: string }

const SiteLink: FC<Props> = ({ className, children, to }) => {
  const parsedCountryPrefix = countryPrefix || '/uk'
  const parsedWpUrl = wpUrl || '#'

  const internalRelativeLinkWithoutCountryPrefix =
    to.startsWith('/') && !to.startsWith(parsedCountryPrefix)

  const internalRelativeLinkWithCountryPrefix = to.startsWith(
    parsedCountryPrefix
  )
  const internalAbsoluteLink = to.startsWith(parsedWpUrl)

  if (to === '#')
    return (
      <a href={to} className={className}>
        {children}
      </a>
    )

  if (internalRelativeLinkWithCountryPrefix || internalAbsoluteLink)
    return (
      <Link data-preload to={sanitiseLink(to)} className={className}>
        {children}
      </Link>
    )

  if (internalRelativeLinkWithoutCountryPrefix)
    return (
      // @ts-ignore
      <Link data-preload to={to} className={className}>
        {/* @ts-ignore */}
        {children}
      </Link>
    )

  return (
    <a
      data-preload
      href={to}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export default SiteLink

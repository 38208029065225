import React from 'react'
import styled from 'styled-components'

import HamburgerIcon from './HamburgerIcon'
import { untilBreakpoint } from '../utils/style/breakpoint'

const Burger = styled.a`
  display: none;
  margin-left: 10px;
  margin-right: 0;
  font-size: 18px;
  padding: 22px 20px;
  background: ${({ theme }) => theme.tokens.space.secondary.regular};
  line-height: 1;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  user-select: none;

  ${untilBreakpoint('lg')} {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 381px) {
    margin-left: 2px;
  }
`

const Hamburger = ({ onClick }) => (
  <Burger onClick={onClick}>
    <HamburgerIcon />
  </Burger>
)

export default Hamburger

import classnames from 'classnames'
import { withPrefix } from 'gatsby'
import React, { useState, useRef, FC, useEffect } from 'react'

import { GRID_UNIT, Icon } from 'mf-ui-components'

import gridTheme from '../../utils/gridTheme'
import { useMediaQuery } from '../../utils/useMediaQuery'
import Hamburger from '../Hamburger'
import {
  FixedWrapper,
  ItemsWrapper,
  MobileHeading,
  NavWrapper,
  Backdrop,
  Logotype,
} from './SiteNavigation.styles'
import { MobileFooter } from './mobile/MobileFooter'
import { MenuNode } from './types'
import { ItemsList as DesktopItemsList } from './desktop/ItemsList'
import { ItemsList as MobileItemsList } from './mobile/ItemsList'

type Props = { menu: { node: MenuNode }[] }

export const SiteNavigation: FC<Props> = ({ menu }) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  const wrapperReference = useRef<HTMLDivElement>(null)

  const isMobileViewport: boolean = useMediaQuery(
    `(max-width: ${gridTheme.breakpoints.lg}px)`
  )

  // Avoid scrolling the page in the background when the menu is open.
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.style.overflow = openMenu ? 'hidden' : 'visible'
    }
  }, [openMenu])

  const closeIconSize = isMobileViewport
    ? `${GRID_UNIT * 4}px`
    : `${GRID_UNIT * 6}px`

  return (
    <NavWrapper className="navWrapper" ref={wrapperReference}>
      <Backdrop className={classnames([{ openMenu }])} />
      <FixedWrapper className={classnames([{ openMenu }])}>
        <MobileHeading>
          <Logotype
            src={withPrefix('/moneyfarm-logotype-2023.svg')}
            alt="Moneyfarm Logo"
          />
          <Icon
            type="icon-chevron-right-big"
            onClick={() => setOpenMenu(false)}
            size={closeIconSize}
            style={{ transform: 'translateX(-4px)', width: closeIconSize }}
          />
        </MobileHeading>

        <ItemsWrapper className={classnames(['itemsWrapper', { openMenu }])}>
          {isMobileViewport ? (
            <MobileItemsList items={menu} />
          ) : (
            <DesktopItemsList items={menu} />
          )}
        </ItemsWrapper>

        {isMobileViewport && <MobileFooter />}
      </FixedWrapper>

      <Hamburger onClick={() => setOpenMenu(currentState => !currentState)} />
    </NavWrapper>
  )
}
